import React from 'react'
import {Cloud, renderSimpleIcon, 
  fetchSimpleIcons,
} from 'react-icon-cloud'
// import {siJavascript, aws} from "simple-icons/icons"

// const icons = [siJavascript, aws].map((icon) => {
//   return renderSimpleIcon({
//     icon,
//     size: 42,
//     aProps: {
//       onClick: (e) => e.preventDefault()
//     }
//   })
// })

// const StaticIconCloud = () => {
//   return <Cloud>
//     {icons}
//   </Cloud>
// }

const useIcons = (slugs) => {
  const [icons, setIcons] = React.useState()
  React.useEffect(() => {fetchSimpleIcons({slugs}).then(({simpleIcons}) => setIcons(Object.values(simpleIcons)))}, [slugs])

  if (icons) {
    return icons.map((icon) => renderSimpleIcon({
      icon,
      size: 40,
      aProps: {
        onClick: (e) => e.preventDefault()
      },
    }))
  }

  return "Loading..."
}

const slugs = [
    'dotnet'
  , 'android', 'amazonaws', 'archlinux', 'autodesk', 'angular', 'apachehive', 'arduino'
  , 'c', 'css3', 'cplusplus', 'csharp'
  , 'django'
  , 'express', 'ethereum'
  , 'firebase', 'flutter'
  , 'git', 'googlesheets', 'github', 'go'
  , 'html5'
  , 'javascript', 'java'
  , 'kalilinux', 'kotlin'
  , 'linux'
  , 'mongodb', 'mysql', 'microsoftsqlserver', 'microsoftexcel'
  , 'nodedotjs', 'nextdotjs', 'notion'
  , 'openai', 'octave', 'opencv'
  , 'php', 'postgresql', 'pwa', 'python'
  , 'react', 'r'
  , 'swift', 'sqlite', 'sass', 'solidity'
  , 'rust', 'raspberrypi'
  , 'tensorflow', 'tableau'
  , 'ubuntu'
  , 'windows'
  , 'xilinx'
]

const DynamicIconCloud = ({style}) => {
  const icons = useIcons(slugs)

  return (
  <Cloud 
    containerProps={{style}}>
    {icons}
  </Cloud>
  )
}


export default DynamicIconCloud;