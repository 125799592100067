import Sections from './Sections';
import React from 'react';
import vskb from './assets/vskb.png';
import sscbc from './assets/sscbc.png';


export default function Resume() {
  window.scrollTo(0, 0);
  // const [timeline, setTimeline] = useState();
  var projects = [
    {
      title: 'Projects',
      items: [
        {
          img: vskb,
          bg: '#aaa',
          title: 'VSK Builders & Promoters',
          text: "Designed and developed a dynamic website for VSK Builders & Promoters, a leading real estate company, featuring project showcases, integrated chat plugins for real-time communication, and streamlined contact information accessibility. Through the utilization of React, React Router, JavaScript, HTML, and CSS, the website significantly enhanced VSK's online presence, fostering increased brand recognition and client engagement. This project contributed to VSK Builders & Promoters' business growth and reputation as a customer-centric organization.",
          buttons: [
            {
              name: 'Visit Website',
              link: 'https://www.vskland.com'
            }
          ]
        },
        {
          img: sscbc,
          bg: '#555',
          title: 'SSC: Version 2',
          text: "Crafted a high-speed college landing website using React and MaterialUI, while also developing a social media web app featuring group creation, file sharing, text posting, group video meetings, payment integration, automated resume and certificate generation. Leveraged blockchain technology to securely store certificates on the Ethereum blockchain, enabling universal verification. Implemented a Python program on Raspberry Pi 4 for facial recognition-based attendance marking. Additionally, authored and published a research paper documenting the project's methodologies and findings.",
          buttons: [
              {
              name: 'View Web App',
              link: 'https://main.d3uvyyoi71ssrp.amplifyapp.com'
              },
              {
              name: 'Paper in IJRPR Journal',
              link: 'https://www.ijrpr.com/uploads/V3ISSUE6/IJRPR5047.pdf'
              }
          ]
          }
      ]
    }
  ]

  const education = [
    {
      title: 'Education',
      items: [
        {
          title: 'Bachelor Degree, Electronics and Communication Engineering',
          institution: 'Sree Sowdambika College of Engineering, Aruppukottai',
          time: 'June 2019 - May 2022',
          description: 'Literally learned stress management, anger management & depression handling techniques. And also learned some electronics and communication core theory.'
        },
        {
          title: 'Diploma, Electrical and Electronics Engineering',
          institution: 'Sri Sowdambika Polytechnic College, Aruppukottai',
          time: 'June 2017 - May 2019',
          description: 'Gained knowledge about Electrical Motors, Microcontroller and Microprocessor programming, PLC programming and Electrical wiring & planning for Residents and Factories.'
        },
        {
          title: 'Secondary & Higher Secondary School',
          institution: 'GHSS, Pandalgudi, Aruppukottai',
          time: '2017',
          description: 'Maths, Physics, Chemistry, Botany, Zoology'
        },
      ]
    }
  ]

  return (
    <div id="resume" className="resume w-100">
      {/* <Switch
            checked={timeline}
            onChange={() => setTimeline(!timeline)}
            name="Timeline"
            color="white"
            />
            {
                timeline
                ?
                <Timeline />
                : */}
    {/* <Container> */}
      <Sections projects={projects} education={education}/>
    {/* </Container> */}
      {/* } */}
    </div>
  );
}
