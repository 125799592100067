export default function Links() {
  return (
    <div className="py-5 text-center">
      <a
        href="https://drive.google.com/drive/folders/1ERy4FtKgIvFNaDZ9ZGb0qdU_4zI1qPhU?usp=sharing"
        target="_blank"
        rel="noreferrer"
      >
        Sem 5 files
      </a>
    </div>
  );
}
