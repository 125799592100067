import React from 'react';
import Card from './Card';
import { Grid } from '@mui/material';
import Cloud from "./Cloud";


const Section = ({ size, title }) => {
  return (
    <div style={{ width: '100%' }} className="">
      <p
        style={{
          width: '100%',
          textAlign: 'center',
          fontSize: size + 'px',
          margin: '40px 0',
          fontWeight: 'bold'
        }}
      >
        {title}
      </p>
    </div>
  );
};


const ImgBoxSlide = ({ list }) => {
  return (
    <Grid
      sx={{ width: '100%'}}
      container
      justifyContent="center"
    >
      {list.map((item, i) => {
        return (
          <Grid 
          sx={{ width: '100%'}}
          key={i} item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Card
              bg={item.bg}
              img={item.img}
              title={item.title}
              text={item.text}
              buttons={item.buttons}
              sx={{ height: '50px'}}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};


export default function Sections({projects, education}) {
  return (
    <div className="w-100">
      <Cloud style={{"backgroundColor": "#eee" }}/>


      {/* <div
        className="work"
        style={{ backgroundColor: '#fff', padding: '20px 10px' }}
      >
        <Section size={40} title={'Work Experience'} />
        <div className="m-5 row d-flex justify-content-center">
          <div className="col-md-4">
            <strong>Web Developer & Digital Marketer</strong>
            <p>(Part Time)</p>
          </div>
          <div className="col-md-4">
            <i>January 2021 - now</i>
          </div>
        </div>
        <div className="m-5 row d-flex justify-content-center">
          <div className="col-md-4">
            <strong>Assistant Electrician & Plumber</strong>
            <p>(Part Time)</p>
          </div>
          <div className="col-md-4">
            <i>July 2021 - October 2021</i>
          </div>
        </div>
      </div> */}

      {
        projects.map((project)=>(
          <div
            className="projects"
            style={{ backgroundColor: '#eee', padding: '20px 10px' }}
          >
            <Section size={30} title={project.title} />
            <ImgBoxSlide
              list={project.items}
              />
          </div>
        ))
      }
      <div className="" style={{ padding: '20px 10px' }}>
        {
          education.map(education=>(
            <div className="" style={{ padding: '20px 10px' }}>
              <Section size={30} title={education.title} />
              {
                education.items.map(course=>(
                  <div className="m-5 row d-flex justify-content-center">
                    <div className="col-md-4">
                      <strong>
                      {course.title}
                      </strong>
                    </div>
                    <div className="col-md-4">
                      <strong>
                        {course.institution}
                      </strong>
                      <br />
                      <i>{course.time}</i>
                      <br />
                      <p>
                      {course.description}
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}
